function search(data, language, resultOutput, noSearchResults, searchResultsAmount, searchResultsAmountCounter, searchTerm) {
    // Filtern der Ergebnisse basierend auf dem Suchbegriff
    const results = data.filter(item => {
        if (item.language === language) {
            return item.text.toLowerCase().includes(searchTerm);
        }
    });

    // Wenn es Ergebnisse gibt
    if (results.length > 0) {

        // Ergebniscontainer leeren
        resultOutput.innerHTML = '';

        // Anzahl der Ergebnisse anzeigen
        noSearchResults.classList.add('hidden');
        searchResultsAmount.classList.remove('hidden');
        searchResultsAmountCounter.innerHTML = results.length;

        // Funktion zum Hervorheben des Suchbegriffs im Text
        const markSearchTerm = (text, searchTerm) => {
            const index = text.toLowerCase().indexOf(searchTerm);

            if (index === -1) {
                return text;
            }

            // Text aufteilen und Suchbegriff hervorheben
            const before = text.slice(0, index);
            const match = text.slice(index, index + searchTerm.length);
            const after = text.slice(index + searchTerm.length);

            return `${before}<span class="highlight">${match}</span>${after}`;
        };

        // Für jedes Suchergebnis
        results.forEach(result => {

            // Neues div-Element erstellen
            const resultItem = document.createElement('li');

            // Klasse 'result-item' hinzufügen
            resultItem.classList.add('search-result');
            let html = '';

            // Wenn es Text gibt und dieser nicht leer ist
            if (result.text && result.text.trim().length > 0) {

                // Text auf 350 Zeichen begrenzen und "..." hinzufügen, falls nötig
                let limitedText = result.text.slice(0, 350);

                if (result.text.length > 350) {
                    limitedText += '...';
                }

                // Suchbegriff im Text hervorheben
                const markedText = markSearchTerm(limitedText, searchTerm);

                // HTML mit hervorgehobenem Text erstellen
                html += `
                    <h5>${result.title}</h5>
                    <span class="result-summary">
                        <p class="result-text">${markedText}</p>
                    </span>
                    <a href="${result.url}" class="more">mehr lesen</a>
                `;
            }

            // HTML dem Ergebnis-Element hinzufügen
            // und das Ergebnis-Element dem Ergebniscontainer hinzufügen
            resultItem.innerHTML = html;
            resultOutput.appendChild(resultItem);
        });

    } else {

        // Suchergebnisse entfernen
        resultOutput.innerHTML = '';

        // Wenn keine Ergebnisse gefunden wurden, entsprechende Meldung anzeigen
        noSearchResults.classList.remove('hidden');
        searchResultsAmount.classList.add('hidden');

        // Anzahl der Suchergebnisse zurücksetzen
        searchResultsAmountCounter.innerHTML = '';
    }
}

// --------------------------
// SEARCH FORM
// --------------------------

function searchForm() {
    const searchInput = document.getElementById('searchInput');
    const resultOutput = document.getElementById('resultOutput');
    const noSearchResults = document.getElementById('noSearchResults');
    const searchResultsAmount = document.getElementById('searchResultsAmount');
    const searchResultsAmountCounter = document.getElementById('searchResultsAmountCounter');
    const jsonPath = '/search-json';
    const language = document.documentElement.lang;

    // Event-Listener für Sucheingabefeld bei Eingabe
    if (searchInput) {

        // Abrufen der JSON-Daten
        fetch(jsonPath)
            .then(function(response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            }).then(function(data) {

            // Texteingabe
            searchInput.addEventListener('input', async (event) => {

                // Suchbegriff in Kleinbuchstaben umwandeln
                const searchTerm = event.target.value.toLowerCase();

                // Wenn der Suchbegriff kürzer als 3 Zeichen ist, Ergebnisse löschen und zurückkehren
                // Anzeige Suchergebnisse ausblenden
                if (searchTerm.length < 3) {
                    resultOutput.innerHTML = '';
                    searchResultsAmount.classList.add('hidden');
                    noSearchResults.classList.add('hidden');

                    return;
                }

                // Index durchsuchen
                search(data, language, resultOutput, noSearchResults, searchResultsAmount, searchResultsAmountCounter, searchTerm);

            });

        })
    }
}

// --------------------------
// SEARCH QUERY
// --------------------------

function searchQuery() {
    let searchInput = document.getElementById('searchInput');
    const resultOutput = document.getElementById('resultOutput');
    const noSearchResults = document.getElementById('noSearchResults');
    const searchResultsAmount = document.getElementById('searchResultsAmount');
    const searchResultsAmountCounter = document.getElementById('searchResultsAmountCounter');
    const jsonPath = '/search-json';
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get('q');
    const language = document.documentElement.lang;

    // Event-Listener für Sucheingabefeld bei Eingabe
    if (searchQuery && searchInput) {

        // Abrufen der JSON-Daten
        fetch(jsonPath)
            .then(function(response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            }).then(function(data) {

            // Suchbegriff in Kleinbuchstaben umwandeln
            const searchTerm = searchQuery.toLowerCase();

            // input ausfüllen
            searchInput.value = searchTerm;

            // Wenn der Suchbegriff kürzer als 3 Zeichen ist, Ergebnisse löschen und zurückkehren
            // Anzeige Suchergebnisse ausblenden
            if (searchTerm.length < 3) {
                resultOutput.innerHTML = '';
                searchResultsAmount.classList.add('hidden');
                noSearchResults.classList.add('hidden');

                return;
            }

            // Index durchsuchen
            search(data, language, resultOutput, noSearchResults, searchResultsAmount, searchResultsAmountCounter, searchTerm);

        })
    }
}

export {
    searchForm,
    searchQuery
}
